<template>
  <section
    :class="{
      'setting-card-small': isSmall,
      'setting-card-medium': isMedium,
      'setting-card-xs': isXs,
      'setting-card--jep': isJEP,
      'setting-card--ce': isCE,
      'setting-card--lcm': isLCM,
      'setting-card--gc': isGC,
    }"
    class="setting-card"
  >
    <div class="setting-card-container">
      <v-card outlined>
        <md-button
          v-if="hasBack"
          class="md-icon-button setting-card-back-button"
          @click="$emit('back')"
        >
          <md-icon class="md-primary"> keyboard_arrow_left</md-icon>
        </md-button>
        <slot />
        <v-card-title class="setting-card-title" v-html="title" />
        <v-card-text
          v-if="content"
          class="setting-card-text"
          v-html="content"
        ></v-card-text>
        <slot name="secondButton"></slot>
        <slot name="primaryButton">
          <md-button
            :to="link"
            class="md-secondary md-raised setting-card-button"
            @click="link ? null : $emit('onClick')"
            :disabled="disable"
            >{{ buttonText }}
          </md-button>
        </slot>
      </v-card>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import vueXlsxTable from "@deevotechvn/vue-xlsx-table";
import Vue from "vue";

Vue.use(vueXlsxTable, { rABS: false });

export default {
  name: "SettingCard",
  props: {
    title: {
      type: String,
      default: "Enterprises",
    },
    content: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "J’Y VAIS !",
    },
    link: {
      type: String,
      default: "",
    },
    hasBack: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isMedium: {
      type: Boolean,
      default: false,
    },
    isXs: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isJEPA", "isJEP", "isCE", "isLCM", "isGC"]),
  },
};
</script>

<style lang="scss">
@import "../styles/variable.scss";

#app {
  .setting-card {
    display: flex;
    flex: 1;
    height: 442px;
    @include for-lg {
      height: 620px;
    }

    .v-card {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      border-radius: 27px;
      border: none;
    }

    &-container {
      width: 100%;
    }

    &-title {
      font-family: var(--font-bold);
      font-size: toRem(27);
      line-height: 1.13;
      text-align: center;
      word-wrap: none;
      word-break: break-word;
      padding: 1rem 0 0 0;
      @include for-lg {
        font-size: toRem(39);
        padding: 19px 30px;
      }
    }

    &.setting-card--jep {
      .setting-card-title {
        font-size: toRem(27);
        @include for-lg {
          font-size: toRem(37);
        }
      }
    }

    &.setting-card--ce {
      .setting-card-title {
        font-size: toRem(27);
        @include for-lg {
          font-size: toRem(35);
        }
      }
    }

    &.setting-card--gc {
      .setting-card-title {
        font-size: toRem(27);
        @include for-lg {
          font-size: toRem(39);
        }
      }
    }

    &.setting-card--lcm {
      .setting-card-title {
        font-size: toRem(27);
        @include for-lg {
          font-size: toRem(38);
        }
      }
    }

    .md-button {
      min-width: unset;
      min-height: unset;
      height: 30px;
      width: 165px;
      font-size: toRem(10);
      margin: 0 0 toRem(9) 0;
      padding: 0;
      @include for-lg {
        font-size: toRem(13);
        width: 250px;
        height: 45px;
      }
      .md-disabled {
        background-color: #80808080;
      }
    }

    &-text {
      width: 100%;
      text-align: center;
      font-size: 17px;
      font-family: var(--font-x);
      color: #000;
      padding: 1rem 0;
      @include for-lg {
        padding: 0 40px 35px 40px;
      }
    }

    .setting-card-back-button {
      position: absolute;
      top: 26px;
      left: 30px;
      width: 36px;
      height: 36px;
      min-width: 36px;
      min-height: 36px;
      margin: 0;
      font-size: 15px;

      .md-icon {
        width: var(--back-button-size-lg);
        height: var(--back-button-size-lg);
        font-size: toRem(24);
        border-radius: 50%;
        @include for-lg {
          width: var(--back-button-size-xl);
          height: var(--back-button-size-xl);
          font-size: toRem(36);
        }
      }
    }

    &.setting-card-medium {
      width: 512px;
      height: 377px;

      .md-button {
        width: 180px;
        height: 45px;
        padding: 12px 60px;
      }
    }

    &.setting-card-small {
      height: 206px;
      margin: 0;
      @include for-lg {
        height: 300px;
      }

      .md-button {
        min-height: unset;
        min-width: unset;
        height: 31px;
        width: 126px;
        margin: toRem(17) 0 0 0;
        @include for-lg {
          width: 180px;
          height: 45px;
          margin: toRem(6) toRem(8);
        }

        .md-ripple {
          font-size: toRem(9);
          @include for-lg {
            font-size: toRem(14);
          }
        }
      }

      .setting-card-title {
        max-width: 400px;
        @media (max-width: 1280px) {
          max-width: 295px;
        }
      }
    }

    &.setting-card-xs {
      width: 270px;
      height: 205px;
      margin: 0;
      @include for-lg {
        width: 364px;
        height: 290px;
      }

      .md-button {
        width: 125px;
        height: 31px;
        min-height: unset;
        min-width: unset;

        .md-ripple {
          font-size: toRem(9);
          @include for-lg {
            font-size: toRem(14);
          }
        }

        @include for-lg {
          width: 180px;
          height: 45px;
          margin: toRem(6) toRem(8);
        }
      }

      .setting-card-title {
        font-size: toRem(23);
        margin: toRem(15) 0;
        font-family: var(--font-bold);
        letter-spacing: -0.83px;
        padding: 0;

        @include for-lg {
          font-size: toRem(33);
        }
      }
    }
  }
}
</style>
