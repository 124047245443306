<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.269 36.765"><path d="M31.737 12.574a5.532 5.532 0 00-.7 11.02v2.154a9.612 9.612 0 01-19.224 0V23.62a11.12 11.12 0 0010.4-11.085V6.649a4.87 4.87 0 00-4.865-4.865h-.514V.708a.703.703 0 10-1.406 0v3.559a.703.703 0 101.406 0V3.191h.514a3.463 3.463 0 013.459 3.459v5.886a9.7 9.7 0 11-19.4 0V6.65a3.463 3.463 0 013.459-3.459h.514v1.076a.703.703 0 101.406 0V.708a.703.703 0 10-1.406 0v1.076h-.514A4.87 4.87 0 00.001 6.649v5.886a11.12 11.12 0 0010.4 11.085v2.132a11.018 11.018 0 0022.036 0v-2.154a5.532 5.532 0 00-.7-11.02zm0 9.659a4.126 4.126 0 114.126-4.126 4.132 4.132 0 01-4.126 4.126z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconArret'
  };
</script>
