<template>
  <div class="socials">
    <Banner>
      <div class="banner-content">
        <MeepIconSocial class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.socials") }}</span>
      </div>
    </Banner>

    <div class="socials-content page-layout">
      <div class="socials-content-cards">
        <div class="socials-content-card">
          <SettingCard
            :isSmall="true"
            :title="$t('socials.pay.title')"
            link="/dashboard/sociale/mes-payes"
          >
            <MeepIconMesPayes class="app-icon" />
          </SettingCard>
        </div>
        <div class="socials-content-card">
          <SettingCard
            :isSmall="true"
            :title="$t('socials.arret.title')"
            link="/dashboard/sociale/arret"
          >
            <MeepIconArret class="app-icon" />
          </SettingCard>
        </div>
        <div class="socials-content-card">
          <SettingCard
            :isSmall="true"
            :title="$t('socials.embauche.title')"
            link="/dashboard/sociale/embauche"
          >
            <MeepIconEmbauche class="app-icon app-icon-embauche" />
          </SettingCard>
        </div>
        <div class="socials-content-card">
          <SettingCard
            :isSmall="true"
            :title="$t('socials.reprise.title')"
            link="/dashboard/sociale/reprise"
          >
            <MeepIconReprise class="app-icon" />
          </SettingCard>
        </div>
        <div class="socials-content-card">
          <SettingCard
            :isSmall="true"
            :title="$t('socials.fin.title')"
            link="/dashboard/sociale/fin-de-contract"
          >
            <MeepIconFindecontrat class="app-icon" />
          </SettingCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSocial from "@/components/icons/MeepIconSocial.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import SettingCard from "@/components/SettingCard";
import MeepIconReprise from "@/components/icons/MeepIconReprise.vue";
import MeepIconMesPayes from "@/components/icons/MeepIconMesPayes.vue";
import MeepIconEmbauche from "@/components/icons/MeepIconEmbauche.vue";
import MeepIconArret from "@/components/icons/MeepIconArret.vue";
import MeepIconFindecontrat from "@/components/icons/MeepIconFindecontrat.vue";

export default {
  name: "Socials",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSocial,
    SettingCard,
    MeepIconReprise,
    MeepIconMesPayes,
    MeepIconEmbauche,
    MeepIconArret,
    MeepIconFindecontrat,
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";

.socials {
  &-content {
    &-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      flex-direction: row;
      gap: 34px;

      .app-icon-embauche {
        width: 42px;
        height: 27px;
        @include for-lg {
          width: 58px;
          height: 37px;
        }
      }
    }
  }
}
</style>
