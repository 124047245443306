<template>
  <div
    class="md-layout banner"
    :style="{
      backgroundImage: backgroundUrl,
    }"
    :class="bannerClass"
  >
    <div v-if="self" class="md-title">
      <slot>
        <p class="banner__title">
          Bonjour,
          <span class="banner__user-name">
            {{ self.firstname[0].toUpperCase() }}. {{ self.lastname }}
          </span>
          - {{ $$filters.formatScope(self.scope) }}
        </p>

        <p v-if="isSuperUser" class="banner__user-name">
          {{ self.username }}
        </p>

        <p class="banner__user-status">
          <span></span>
        </p>
      </slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Banner",

  props: {
    isBig: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters([
      "self",
      "isJEPA",
      "isCE",
      "isLCM",
      "isGC",
      "isMEEP",
      "isJEP",
      "isSuperUser",
      "organizationTheme",
    ]),

    bannerClass() {
      return {
        "app-jepa__background": this.isJEPA,
        "banner--jepa": this.isJEPA,
        "banner--lcm": this.isLCM,
      };
    },

    bannerSubClass() {
      return {
        "big-banner": this.$route.path,
      };
    },

    backgroundUrl() {
      if (this.isJEPA) {
        return "";
      }

      let imageName;

      if (this.isGC) {
        imageName = "dashboard_gc.png";
      }

      if (this.isLCM) {
        imageName = "dashboard_lcm.png";
      }

      if (this.isCE) {
        imageName = "dashboard_ce.png";
      }

      if (this.isJEP) {
        imageName = "bg-19.png";
      }

      if (this.isMEEP) {
        imageName = "dashboard_meep.jpg";
      }

      return `url(${require(`../assets/images/${imageName}`)})`;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;
  flex: 0 0 70px;
  margin-bottom: 20px;
  padding-left: 20px;
  color: #ffffff;

  @media (max-width: 600px) {
    .md-title {
      padding: 1rem 0;
      flex-direction: column;

      .banner__title {
        margin: 0;
        line-height: 1;
      }

      .banner__user-name {
        margin: 0;
        line-height: 1;
      }

      .banner__user-status {
        margin: 0;
        font-size: 1rem;
        line-height: 1;
      }
    }
  }

  @include for-lg {
    height: 100px;
    flex: 0 0 100px;
    padding-left: 3rem;
    margin-bottom: 34px;
  }

  .banner__user-name {
    font-weight: bold;
    font-family: var(--font-bold);
  }

  .banner__user-status,
  .banner__user-name,
  .banner__title {
    font-size: toRem(20);

    @include for-lg {
      font-size: 29px;
    }
  }
  &.banner--lcm {
    .banner__user-name {
      font-family: var(--font-bold);
    }
  }
  &.banner--jepa {
    padding-top: 22px;
    padding-bottom: 28px;
    color: #003382;

    .banner__user-name,
    .banner__title,
    .banner__user-status {
      font-size: 31px;
      font-family: var(--font-book);
      line-height: 36px;
      margin-block: 0;
      margin-inline: 0;
    }

    .banner__user-name {
      font-family: var(--font-bold);
    }

    .banner-content {
      display: flex;
      align-items: center;
      &__icon {
        width: 30px;
        height: 30px;
        color: var(--bg-primary);

        path {
          fill: var(--bg-primary);
        }

        &-stroke {
          circle {
            stroke: var(--bg-primary);
            fill: transparent;
          }

          path {
            stroke: var(--bg-primary);
            fill: transparent;
          }
        }

        &-inverted {
          circle {
            stroke: var(--bg-primary);
            fill: transparent;
          }
        }

        &-sub-inverted {
          path {
            fill: transparent;
            stroke: var(--bg-primary);
          }
        }

        &-chat {
          width: 30px;
          height: 30px;
        }
      }

      &__icon-stroke {
        path {
          fill: none;
          stroke: var(--bg-primary);
        }
      }
      &__text {
        margin-left: 15px;
        font-family: var(--font-bold);
        font-size: toRem(19);
        @include for-lg{
          font-size: toRem(31);
        }
      }
    }
  }

  &-content {
    display: flex;
    align-items: center;
    margin-left: 10px;
    &__icon {
      width: 30px;
      height: 30px;
      color: var(--bg-primary);

      path {
        fill: var(--text-primary);
      }

      &-stroke {
        circle {
          stroke: var(--text-primary);
          fill: transparent;
        }

        path {
          stroke: var(--text-primary);
          fill: transparent;
        }
      }

      &-inverted {
        circle {
          stroke: var(--text-primary);
          fill: transparent;
        }
      }

      &-sub-inverted {
        path {
          fill: transparent;
          stroke: var(--text-primary);
        }
      }
    }

    &__icon-stroke {
      path {
        fill: none;
        stroke: var(--text-primary);
      }
    }
    &__text {
      margin-left: 15px;
      font-family: var(--font-bold);
      font-size: toRem(20);
      @include for-lg{
        font-size: toRem(31);
      }
    }
  }
  .md-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
