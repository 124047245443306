const HasBackMixin = {
  methods: {
    goBack() {
      window.history.back();
    },
    backToHome() {
      this.$router.push("/dashboard/home");
    },
  },
};

export default HasBackMixin;
